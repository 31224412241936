@use "./typography" as *;

*,
**::before,
**::after {
  box-sizing: border-box;
}

body {
  font-family: "Montserrat";
  font-variation-settings: "wght" 400;
  margin: 0rem;
  background-color: rgba(238, 238, 238, 0.685);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}