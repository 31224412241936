@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.footer {
    background-color: $color-primary;
    color: rgb(196, 196, 196);
    text-align: center;
    padding: 2rem;
    margin-top: -2rem;

    @include tablet {
        padding: 4rem 2rem;
    }

    &__icon {
        margin-top: -7rem;
        width: 5rem;
    }

    &__text {
        font-size: 1rem;
        font-variation-settings: "wght" 600;

        @include tablet {
            font-size: 1.25rem;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__subtext {
        font-size: .75rem;

        @include tablet {
            font-size: 1rem;
        }
    }

    &__tech-icon {
        width: 3rem;
    }

}