@use "./variables" as *;
@use "./typography" as *;

// media breakpoints
@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
      @content;
    }
  }
  
  @mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
      @content;
    }
  }


// general
@mixin button-style {
    background-color: transparent;
    border-color: $color-primary;
    border-width: .0938rem;
    color: $color-primary;
    border-radius: 18155.375rem;
    cursor: pointer;
    font-family: "Montserrat";
    width: 8rem;
    height: 3rem;
    font-variation-settings: "wght" 600;
    align-self: center;
    margin-top: .5rem;
    margin-bottom: 2rem;
}

@mixin card {
  padding: 2rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin link-style {
  text-decoration: none;
  background-color: transparent;
  border-color: $color-primary;
  border-width: .0938rem;
  color: $color-primary;
  border-radius: 18155.375rem;
  width: 8rem;
  font-size: .875rem;
  padding: .7rem 0;
  font-variation-settings: "wght" 600;
  font-family: "Montserrat";
  border: .0938rem solid $color-primary;
  align-self: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
