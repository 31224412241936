@use "../../styles/mixins" as *;

.project-list {
    position: relative;
    bottom: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
    }
}