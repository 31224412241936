@use "../../styles/variables" as *;
@use "../../styles/typography" as *;
@use "../../styles//mixins" as *;

.prompt{
  background-color: $color-primary;
    display:flex;
    flex-direction: column;
    justify-content:center;
    margin:0;
    height:95vh;
    align-items:center;
    align-content:center;
    padding:0 8rem;


    &__thought-container {
      display:flex;
      flex-flow:row wrap;
      justify-content:center;
      margin:0;
      height:100%;
      align-items:center;
      align-content:center;
    }

    &__thought {
      margin: 1rem;
      display:flex;
      background-color:#fff;
      padding:20px;
      border-radius:30px;
      width: 15rem;
      min-height:25%;
      margin:20px;
      position:relative;
      align-items:center;
      justify-content:center;
      text-align:center;
      z-index: 1;

      @include tablet {
        width: 30rem;
      }

      @include desktop {
          width: 30rem;
      }

      &:before {
        content:"";
        background-color:#fff;
        border-radius:50%;
        display:block;
        position:absolute;
        z-index: 1;
        width:44px;
        height:44px;
        top:-12px;
        left:28px;
        box-shadow:-50px 30px 0 -12px #fff;
      }
      &:after {
        content:"";
        background-color:#fff;
        border-radius:50%;
        display:block;
        position:absolute;
        z-index: 1;
        bottom:-10px;
        right:26px;
        width:30px;
        height:30px;
        box-shadow:40px -34px 0 0 #fff,
                   -28px -6px 0 -2px #fff,
                   -24px 17px 0 -6px #fff,
                   -5px 25px 0 -10px #fff;
        
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__image {
      width: 100%;
      border-radius: 30px;
      z-index: 2;
    }

    &__avatar {
      width: 8rem;
      border-radius: 30px;
      margin: 1rem;

      @include tablet {
        width: 15rem;
      }

      @include desktop {
          width: 18rem;
      }
      
    }

    &__text {
      resize: none;
      padding: .5rem;
      width: 13rem;
      border-radius: 5px;
      height: 2rem;

      &::placeholder {
        font-size: .625rem; 
      }
    }

    &__button {
      @include button-style;
      color: white;
      font-weight: bold;
      background-color: black;
    }

}
