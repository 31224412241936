@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.footer-banner {
    position: relative;
    bottom: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #041738;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    color: white;
    padding: 1rem;

    @include desktop {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 50rem;
        padding: 3rem;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        font-size: 1.25rem;
        font-variation-settings: "wght" 700;
    }

    &__text {
        font-size: 1.15rem;
        line-height: 1.5rem;
        margin: 0rem;

        @include desktop {
            width: 15rem
        }
    }

    &__link {
        @include link-style;
        border-color: #6f8cbe;
        border-width: .125rem;
        font-size: 1.15rem;
        width: 12rem;
        color: white;
        margin: 1rem;

        &:hover {
            background-color: #6f8cbe;
            color: white;
            transition:.2s ease-in-out;
        }
    }
}