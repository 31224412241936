@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.about-me {
    position: relative;
    bottom: 18rem;
    margin: 1rem;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;

    @include tablet {
        margin: 1rem 4rem;
    }

    @include desktop {
        flex-direction: row;
    }



    &__card {
        @include card;

        @include tablet {
            padding: 4rem 3rem;
        }

        @include desktop {
            min-width: 32%;
            border-right: .0313rem solid rgba(128, 128, 128, 0.164);
        }

        &--last {
            @include desktop {
                border-right: none;
            }
        }
    }

    &__icon {
        width: 4rem;

        @include tablet {
            width: 6rem;
        }
    }

    &__title {
        font-variation-settings: "wght" 700;
        font-size: 1.125rem;
        margin-bottom: 0;

        @include tablet {
            font-size: 1.5rem;
        }
    }

    &__sub-text {
        font-size: .875rem;
        text-align: center;
        padding: 1rem;
        line-height: 1.3rem;
        margin: 0;

        @include tablet {
            font-size: 1.15rem;
            line-height: 1.7rem;
        }
    }

    &__subtitle {
        font-size: 1rem;
        font-variation-settings: "wght" 600;
        margin-bottom: 0;

        @include tablet {
            font-size: 1.3rem;
        }
    }

    &__tech-stack-container {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include tablet {
            margin-top: 1.5rem;
        }
    }

    &__tech-stack-icon {
        width: 3rem;
        margin: .7rem;

        @include tablet {
            width: 5rem;
            margin: 1rem;
        }
    }

    &__favorite-space-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;

        @include tablet {
            margin-top: 1.5rem;
        }
    }

    &__favorite-space-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .25rem;

        @include tablet {
            margin-top: .5rem;
        }
    }

    &__checkmark-icon {
        width: 1rem;
        height: 1rem;
        margin-right: .25rem;

        @include tablet {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: .5rem;
        }
    }

    &__favorite-space-text {
        margin: 0;
        font-size: .875rem;

        @include tablet {
            font-size: 1.15rem;
        }
    }

    &__flags-container {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__flag {
        width: 3rem;
        margin: .7rem;

        @include tablet {
            width: 5rem;
            margin: 1rem;
        }
    }

    &__sports-container {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__sports-icon {
        width: 3rem;
        margin: 1rem;

        @include tablet {
            width: 5rem;
            margin: 2rem;
        }
    }

    &__instruments-container {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__instrument-icon {
        width: 3rem;
        margin: 1rem;

        @include tablet {
            width: 5rem;
            margin: 2rem;
        }
    }

}